import React, { useEffect } from 'react';
import InputField from 'components/fields/InputField';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useNavigate } from 'react-router-dom';

import { SnackbarState, UserRegister } from 'models/user-models';
import { registerUser } from 'api';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import AppSnackbar from 'components/snakbar';

const Register = () => {

    const getExpirationDate = () => {
        const currentDate = new Date();
        const expirationDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
        // const expirationDate = new Date(currentDate.getTime() + 3 * 60 * 1000);
        return expirationDate.toISOString(); // Convert to ISO string in UTC
      };

    const [userCredentials, setUserCredentials] = React.useState<UserRegister>({
  
    } as UserRegister);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = React.useState<SnackbarState>({
        open: false,
        message: '',
        severity: 'info', 
    });
      
      
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let cleanedValue = value;
        if (name === "fname" || name === "lname") {
            cleanedValue = value.replace(/[^A-Za-z]/g, '');
        } else if (name === "contact") {
            cleanedValue = value.replace(/[^0-9+]/g, '');
            const plusCount = (cleanedValue.match(/\+/g) || []).length;
            if (plusCount > 1) {
                cleanedValue = cleanedValue.replace(/\+/g, '');
            }
            if (cleanedValue.indexOf('+') > 0) {
                cleanedValue = cleanedValue.replace(/\+/g, '');
            }
        } else if (name === "email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                setErrorMessage('Please enter a valid email address');
                setIsError(true);
              
            }
        }

        setUserCredentials({ ...userCredentials, [name]: cleanedValue ,   trial_expiry: getExpirationDate(),});
    };


    useEffect(()=>{
        setSnackbar({ open: true, message: 'User will register as a 7 day free trial', severity: 'info' });
    },[])

    const validateFields = () => {
        const requiredFields = ['fname', 'lname', 'email', 'password', 'contact', 'dob'];
        for (const key of requiredFields) {
            const value = userCredentials[key as keyof UserRegister];
            if (!value || (typeof value === 'string' && value.trim() === '')) {
                return true;
            }
        }
        return false;
    };

    const handleDateChange = (date: Date | null, type?: string) => {
        if (type) {
            const newDate = userCredentials.dob ? new Date(userCredentials.dob) : new Date();
            if (type === 'year') {
                newDate.setFullYear(date.getFullYear());
            } else if (type === 'month') {
                newDate.setMonth(date.getMonth());
            } else if (type === 'day') {
                newDate.setDate(date.getDate());
            }
            setUserCredentials({ ...userCredentials, dob: newDate });
        }
    };
    

    const createUser = async () => {
        setLoading(true);
        try {
            if (validateFields()) {
                setErrorMessage('Required fields must be filled in');
                
                setIsError(true);
                return;
            }

            const res = await registerUser(userCredentials);
            if (res?.token) {
                setErrorMessage('User registered successfully');
                setSnackbar({ open: true, message: '7 day trial has been started ', severity: 'success' });
                setIsError(true);
                setTimeout(() => {
                    navigate('/auth/sign-in');
                }, 1000);
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Error occurred while creating user');
            setIsError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsError(false);
    };

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const filterFutureDates = (date: Date) => {
        return date <= yesterday;
    };

    return (
        <div className="flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
            <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">Register</h4>
                <p className="mb-9 ml-1 text-[15px] xl:text-[16px] text-gray-600">
                    Please register your information to continue using our platform.
                </p>

                <div className="flex justify-between">
                    <div className="w-[49%]">
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="First Name"
                            placeholder="John"
                            id="firstName"
                            name="fname"
                            type="text"
                            value={userCredentials.fname || ''}
                            onChange={onChange}
                            importantField={true}
                        />
                    </div>
                    <div className="w-[49%]">
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="Last Name"
                            placeholder="Doe"
                            id="lastName"
                            name="lname"
                            type="text"
                            value={userCredentials.lname || ''}
                            onChange={onChange}
                            importantField={true}
                        />
                    </div>
                </div>
                <div className="xl:flex lg:block">
                    <div className="w-[70%] mr-2.5">
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="Phone Number"
                            name="contact"
                            placeholder="03158897232"
                            id="phoneNumber"
                            type="text"
                            value={userCredentials.contact?.toString() || ''}
                            onChange={onChange}
                            importantField={true}
                        />
                    </div>
                    <div className="w-[60%]">
                        <label
                            htmlFor="date"
                            className={`font-poppins text-sm text-navy-700 dark:text-white ml-1.5 font-medium`}
                        >
                            Date of birth <span className="!text-[#ff0000]">*</span>
                        </label>
                        <div className="flex mt-2">
                            <DatePicker
                                className="flex h-12 w-[69px] items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                                selected={userCredentials.dob}
                                onChange={(date: Date | null) => handleDateChange(date, 'year')}
                                dateFormat="yyyy"
                                showYearPicker
                                placeholderText="2024"
                                maxDate={yesterday}
                                filterDate={filterFutureDates}
                            />
                            <DatePicker
                                className="flex h-12 w-[80px] items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ml-1.5"
                                selected={userCredentials.dob}
                                onChange={(date: Date | null) => handleDateChange(date, 'month')}
                                dateFormat="MMMM"
                                showMonthYearPicker
                                placeholderText="March"
                                maxDate={yesterday}
                                filterDate={filterFutureDates}
                            />
                            <DatePicker
                                className="flex h-12 w-[54px] items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ml-1.5"
                                selected={userCredentials.dob}
                                onChange={(date: Date | null) => handleDateChange(date, 'day')}
                                dateFormat="dd"
                                placeholderText="10"
                                maxDate={yesterday}
                                filterDate={filterFutureDates}
                            />
                        </div>
                    </div>
                </div>
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Email"
                    placeholder="johndoe@gmail.com"
                    id="email"
                    name="email"
                    type="text"
                    value={userCredentials.email || ''}
                    onChange={onChange}
                    importantField={true}
                />
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Password"
                    placeholder="*********"
                    id="password"
                    name="password"
                    type="password"
                    value={userCredentials.password || ''}
                    onChange={onChange}
                    importantField={true}
                />
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Practice Address"
                    placeholder="Street 239, House 418 Myung Street, Germany"
                    id="address"
                    type="text"
                    name="address"
                    value={userCredentials.address || ''}
                    onChange={onChange}
                />
                <button
                    className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    onClick={() => {
                        setUserCredentials({ ...userCredentials, role_id: 2 });
                        if (userCredentials && (!userCredentials.fname || !userCredentials.lname || !userCredentials.fname.match(/^[A-Za-z]+$/) || !userCredentials.lname.match(/^[A-Za-z]+$/))) {
                            setErrorMessage('Please enter valid name');
                            setIsError(true);
                            return;
                        }

                        if (userCredentials && userCredentials.email && !userCredentials.email.endsWith('.com')) {
                            setErrorMessage('Please enter a valid email address');
                            setIsError(true);
                            return;
                        }

                        createUser();
                    }}
                >
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Register'}
                </button>
                <div className="mt-4">
                    <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">Already have an account?</span>
                    <Link to="/auth/sign-in" className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white">
                        Sign in
                    </Link>
                </div>
            </div>
            <Snackbar open={isError} autoHideDuration={6000} onClose={handleClose} message={errorMessage} />
            <AppSnackbar
                isOpen={snackbar.open}
                title={snackbar.message}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                severity={snackbar.severity}
            />
        </div>
    );
};

export default Register;
