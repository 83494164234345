import React, { ChangeEvent, useEffect, useState } from "react";
import Card from "components/card";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { BsFillNodePlusFill, BsArrowDown, BsArrowUp } from "react-icons/bs";
import InputField from "components/fields/InputField";
import GeneralModal from "usefulComponents/Modal/Modal";
import Select from 'react-select'
import DataTable from "components/Datatable/Datatable";
import { GridColDef } from "@mui/x-data-grid";
import { deleteDataById, fetchData, insertData, updatetDataById } from "api";
import { systems } from "api/collection_names";
import { Button } from "@mui/material";
import { SelectModel } from "utils/general-utils";
import { useNavigate } from "react-router-dom";
import { SnackbarState } from "models/user-models";
import AppSnackbar from "components/snakbar";

export interface SystemsType {
    description: string
    priority: number
    _id?: string
}

const Systems = () => {
    const [open, setOpen] = React.useState(false)
    const [isEdit, setIsEdit] = React.useState(false)
    const [editId, setEditId] = React.useState("0")
    const [rows, setRows] = useState<[]>([])
    const [dataObj, setDataObj] = useState<SystemsType>({
        description: "",
        priority: 0
    })
    const [options, setOptions] = useState<SelectModel[]>([{ value: 'null', label: 'None' }])
    const [loading, setLoading] = useState<boolean>(false)
    const [snackbar, setSnackbar] = useState<SnackbarState>({
        open: false,
        message: '',
        severity: 'info', 
    });

const navigate = useNavigate()
    useEffect(() => {
        getSystems()
    }, [])

    const initObj = () => {
        setDataObj({
            description: "",
            priority: 0
        })
    }




    const getSystems = async () => {
        try {
            
            setLoading(true)
            const res = await fetchData(systems)
            if (res.success) {
                let sortData = res.data.sort((a: any, b: any) => a.priority - b.priority);
                setRows(sortData)
                const arr: SelectModel[] = [{ value: 'null', label: 'None' }]
                for (let index = 0; index < res.data.length; index++) {
                    const x = res.data[index];
                    arr.push({ value: x._id, label: x.description })
                }
                setOptions(arr)
            }
            else {
                setRows([])
                
                // const isTokenExpired = () => {
                //     const expirationTime = localStorage.getItem('expirationTime');
                //     if (!expirationTime) return true;
                //     return new Date().getTime() > Number(expirationTime);
                //   };
                //   console.log("isTokenExpired++++++",isTokenExpired())
                  
                //   if (isTokenExpired()) {
                //     localStorage.removeItem('expirationTime');
                //     navigate('/auth/sign-in'); 
                //   } 
                //   navigate('/auth/sign-in'); 
                alert("No System found...!")
                navigate('/auth/sign-in'); 
            }
            setLoading(false)
        } catch (error:any) {
            const alreadyLoggedIn = error.message.endsWith("401")
            if(alreadyLoggedIn){
                setSnackbar({ open: true, message: "logged in from some other device", severity: 'error' });
                setTimeout(()=>{
                    navigate('/auth/sign-in'); 
                },3000)
            }
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDataObj((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    const columns: GridColDef[] = [
        { field: "description", headerName: "Description", flex: 9 },
        {
            field: "actions",
            headerName: "Actions",
            flex: 2,
            sortable: false,
            renderCell: (params: any) => (
                <>
                    <Button
                        title="up"
                        variant="contained"
                        sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "#e5e5e5", color: "black" }}
                        onClick={async () => {
                            if (params.row.priority > 0) {

                                let data: SystemsType[] = rows
                                let row1 = data.find(row => row.priority === params.row.priority)
                                let row2 = data.find(row => row.priority === params.row.priority - 1)

                                await updatetDataById(systems, {
                                    description: row1?.description,
                                    priority: row1?.priority - 1
                                }, row1?._id).then(res => console.log(res)).catch(e => console.log(e))

                                await updatetDataById(systems, {
                                    description: row2?.description,
                                    priority: row2?.priority + 1
                                }, row2?._id).then(res => console.log(res)).catch(e => console.log(e))

                                getSystems()
                            }

                        }}>
                        <BsArrowUp />

                    </Button>
                    <Button
                        title="down"
                        variant="contained"
                        sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "#e5e5e5", color: "black" }}
                        onClick={async () => {
                            if ((params.row.priority <= 0) || (params.row.priority !== rows.length - 1)) {
                                let data: SystemsType[] = rows
                                let row1 = data.find(row => row.priority === params.row.priority)
                                let row2 = data.find(row => row.priority === params.row.priority + 1)

                                await updatetDataById(systems, {
                                    description: row1?.description,
                                    priority: row1?.priority + 1
                                }, row1?._id).then(res => console.log(res)).catch(e => console.log(e))

                                await updatetDataById(systems, {
                                    description: row2?.description,
                                    priority: row2?.priority - 1
                                }, row2?._id).then(res => console.log(res)).catch(e => console.log(e))
                                getSystems()
                            }
                        }}>
                        <BsArrowDown />
                    </Button>
                    <Button
                        title="Edit"
                        variant="contained"
                        sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "#eed202", color: "white" }}
                        onClick={() => {
                            setDataObj((prev) => ({
                                ...prev,
                                description: params.row.description
                            }))
                            setOpen(true)
                            setIsEdit(true)
                            setEditId(params.row._id)
                        }}>
                        <EditIcon fontSize="small" />
                    </Button>
                    <Button
                        title="Delete"
                        sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "red", color: "white" }}
                        variant="contained"
                        onClick={async () => {
                            /* eslint-disable no-restricted-globals */
                            if (confirm(`Are you sure to delete ${params.row.description}`)) {
                                const res = await deleteDataById(systems, params.row._id)
                                if (res.success) {
                                    getSystems()
                                }
                                else {
                                    alert(res.message)
                                }
                            }
                        }}>
                        <DeleteIcon fontSize="small" />
                    </Button>
                </>
            ),
        }
    ];

    return (
        <div>
            <div className="mt-5">
                <Card extra={"w-full h-full sm:overflow-auto px-8"}>
                    <header className="relative flex items-center justify-between py-5">
                        <h1 className="text-[26px] text-white font-semibold w-[100%] sm:w-[70%]">Questions</h1>

                        <button onClick={() => {
                            setOpen(true)
                            setIsEdit(false)
                            initObj()
                        }} className={`flex items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10" linear justify-center rounded-lg font-bold transition duration-200`}>
                            Add System &nbsp;&nbsp; <BsFillNodePlusFill />
                        </button>
                    </header>
                    <GeneralModal openModal={open} title="Add Systems" saveButtonText="Submit" cancelButtonText="Close" onClose={setOpen} onSave={async () => {
                        if (!dataObj.description || dataObj.description.trim() === "") {
                            alert("Description required...!")
                            return
                        }
                        if (!isEdit) {
                            let postdata = dataObj
                            postdata.priority = rows.length
                            const res = await insertData(systems, dataObj)
                            if (res.success) {
                                getSystems()
                                setOpen(false)
                            }
                            else {
                                alert(res.message)
                            }
                        }
                        else {
                            const res = await updatetDataById(systems, dataObj, editId)
                            if (res.success) {
                                getSystems()
                                setOpen(false)
                            }
                            else {
                                alert(res.message)
                            }
                        }
                    }} >
                        <div className="mt-10 flex justify-between items-center w-[100%]">
                            <div className="w-[450px] mr-5">
                                <InputField
                                    name="description"
                                    variant="auth"
                                    extra="mb-3"
                                    label="Description"
                                    placeholder="Description"
                                    type="text"
                                    id="description"
                                    value={dataObj.description.replace("  ", " ")}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </GeneralModal>

                    <div>
                        <DataTable columns={columns} rows={rows} loading={loading} />
                    </div>
                </Card>
            </div>
            <AppSnackbar
                isOpen={snackbar.open}
                title={snackbar.message}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                severity={snackbar.severity}
            />
        </div>
    );
};

export default Systems;
