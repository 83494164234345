import { Box } from "@mui/material";
import { WizardProps, formatReportData } from "models/wizard/wizard-modals";
import { report } from "process";
import React from "react";
import { MdCheck } from "react-icons/md";

const WizardReport: React.FC<WizardProps> = ({
  userReport,
  duration,
  durationType,
  complaintRemark,
  impressionRemark,
  examinationRemark,
  subComplaintRemark,
  data,
}) => {
  const [textCopied, setTextCopied] = React.useState(false);

  const handleCopyToClipboard = async () => {
  
    try {
      const report = {
        ...userReport,
        duration: duration,
        durationType: durationType,
        complaintRemark,
        subComplaintRemark,
        impressionRemark,
        examinationRemark,
      };
      console.log("report in copied ======>",report)
      const formattedData = formatReportData(report);
      console.log("report in copied formattedData ======>",formattedData)
      await navigator.clipboard.writeText(formattedData);
      setTextCopied(true);
      setTimeout(() => {
        setTextCopied(false);
      }, 5000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const mergeWithoutDuplicates = (array1: any, array2: any) => {
    const set = new Set(array1.map((item: any) => item.description));
    const filteredArray2 = array2.filter(
      (item: any) => !set.has(item.description)
    );
    return [...array1, ...filteredArray2];
  };

  const mergedData = mergeWithoutDuplicates(data, userReport.examinations);

  return (
    <>
      <h1 className="text-black mb-5 w-[100%] text-left text-[26px] font-semibold sm:w-[70%]">
        Reports
      </h1>
      <hr />

      <div className="mt-3" style={{ height: "80%", overflowY: "scroll" }}>
        {/* <h1 className="mt-3 text-[20px] text-black font-semibold w-[100%] sm:w-[100%] text-left">Complaint:</h1> */}
        <p className="text-black w-[100%] text-[14px] sm:w-[100%]">
          {userReport.compliants?.map((complaint, index) => {
            return (
              <React.Fragment key={index}>
                {index === 0 ? "C/O " : ""}
                {`${complaint.description} for ${duration} ${durationType}`}
                {(complaint as any).scroll_downs &&
                  (complaint as any).scroll_downs.length > 0 && (
                    <>
                      {(complaint as any).scroll_downs.map(
                        (scroll_down: any, index: any) => (
                          <React.Fragment key={index}>
                            <strong> ( {scroll_down})</strong>
                            {index < (complaint as any).scroll_downs.length - 1
                              ? ", "
                              : ""}
                          </React.Fragment>
                        )
                      )}
                    </>
                  )}
                <br />
              </React.Fragment>
            );
          })}
          <br />
          {complaintRemark && (
            <>
              {/* <strong>Remarks</strong> : */}
              <React.Fragment>
                <strong>{complaintRemark}</strong>
              </React.Fragment>
            </>
          )}
        </p>
        <p className="text-black w-[100%] text-[14px] sm:w-[100%]">
          {userReport.subComplaints?.map((sub_complaint, index) => {
            const isLast = index === userReport.subComplaints.length - 1;
            const separator = isLast
              ? ""
              : index === userReport.subComplaints.length - 2
              ? " and "
              : ", ";
            return (
              <React.Fragment key={index}>
                {index === 0 ? "Patient also has " : ""}
                {`${sub_complaint.description}`}
                {separator}
              </React.Fragment>
            );
          })}
          <br />
          {subComplaintRemark && (
            <>
              {/* <strong>subComplaintRemark</strong> : */}
              <React.Fragment>
                <strong>{subComplaintRemark}</strong>
              </React.Fragment>
            </>
          )}
        </p>

        {mergedData.length > 0 && (
          <>
            <h1 className="text-black mt-3 w-[100%] text-left text-[20px] font-semibold sm:w-[100%]">
              On Examination:
            </h1>
            <p className="text-black w-[100%] text-[14px] sm:w-[100%]">
              {mergedData.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <Box display={"flex"} alignItems={"center"} gap={"5px"}>
                      <h1>{`${(item as any).description || ""} `}</h1>
                      {(item as any).value ? (
                        <Box display={"flex"} alignItems={"center"} gap={"5px"}>
                          <h5 style={{ fontWeight: "bold" }}>
                            {` ${(item as any)?.value?.inputValue}`}{" "}
                            {(item as any)?.value?.unitValue === "Kilos"
                              ? "Kg"
                              : (item as any)?.value?.unitValue === "Pounds"
                              ? "Lb"
                              : (item as any)?.value?.unitValue === "Feets"
                              ? "ft"
                              : (item as any)?.value?.unitValue === "Meters"
                              ? "m"
                              : (item as any)?.value?.unitValue === "Celsius"
                              ? "C"
                              : (item as any)?.value?.unitValue === "Fahrenheit"
                              ? "F"
                              : (item as any)?.value?.unitValue}
                          </h5>
                        </Box>
                      ) : (
                        ""
                      )}
                      {(item as any).scroll_downs &&
                        (item as any).scroll_downs.length > 0 && (
                          <>
                            {(item as any).scroll_downs.map(
                              (scroll_down: any, index: any) => (
                                <React.Fragment key={index}>
                                  <strong>( {scroll_down})</strong>
                                  {index < (item as any).scroll_downs.length - 1
                                    ? ", "
                                    : ""}
                                </React.Fragment>
                              )
                            )}
                            <br />
                          </>
                        )}
                    </Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      gap={"5px"}
                    ></Box>
                  </React.Fragment>
                );
              })}
              {examinationRemark && (
                <>
                  {/* <strong>Remarks</strong> : */}
                  <React.Fragment>
                    <strong>{examinationRemark}</strong>
                  </React.Fragment>
                </>
              )}
            </p>
          </>
        )}

        {userReport.impressions && userReport.impressions.length > 0 && (
          <>
            <h1 className="text-black mt-3 w-[100%] text-left text-[20px] font-semibold sm:w-[100%]">
              Impression:
            </h1>
            <p className="text-black w-[100%] text-[14px] sm:w-[100%]">
              {userReport.impressions?.map((impression) => {
                return (
                  <React.Fragment>
                    {impression.description}
                    {(impression as any).scroll_downs &&
                      (impression as any).scroll_downs.length > 0 && (
                        <>
                          {(impression as any).scroll_downs.map(
                            (scroll_down: any, index: any) => (
                              <React.Fragment key={index}>
                                <strong> ( {scroll_down})</strong>
                                {index <
                                (impression as any).scroll_downs.length - 1
                                  ? ", "
                                  : ""}
                              </React.Fragment>
                            )
                          )}
                        </>
                      )}
                    <br />
                  </React.Fragment>
                );
              })}

              {impressionRemark && (
                <>
                  {/* <strong>Remarks</strong> : */}
                  <React.Fragment>
                    <strong>{impressionRemark}</strong>
                  </React.Fragment>
                </>
              )}
            </p>
          </>
        )}

        {userReport.treatment_plans &&
          userReport.treatment_plans.length > 0 && (
            <>
              <h1 className="text-black mt-3 w-[100%] text-left text-[20px] font-semibold sm:w-[100%]">
                Treatment Plan:
              </h1>
              <p className="text-black w-[100%] text-[14px] sm:w-[100%]">
                {userReport.treatment_plans?.map((treatment) => {
                  return (
                    <React.Fragment>
                      {treatment.description}
                      {(treatment as any).scroll_downs &&
                        (treatment as any).scroll_downs.length > 0 && (
                          <>
                            {(treatment as any).scroll_downs.map(
                              (scroll_down: any, index: any) => (
                                <React.Fragment key={index}>
                                  <strong> ( {scroll_down})</strong>
                                  {index <
                                  (treatment as any).scroll_downs.length - 1
                                    ? ", "
                                    : ""}
                                </React.Fragment>
                              )
                            )}
                               <br />
                          </>
                        )}
                      <br />
                    </React.Fragment>
                  );
                })}
              </p>
            </>
          )}
      </div>

      <button
        onClick={handleCopyToClipboard}
        className={`${
          textCopied
            ? "finishButton items-left w-fit"
            : "w-40 items-center justify-center"
        } dark:active:bg-white/10"  linear mr-3 mt-3 flex rounded-lg bg-lightPrimary p-3 text-sm font-bold text-brand-500 transition duration-200 hover:cursor-pointer hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20`}
      >
        {textCopied ? (
          <>
            Copied to Clipboard{" "}
            <MdCheck style={{ width: 20, height: "auto" }} className="ml-3" />
          </>
        ) : (
          "Copy to Clipboard"
        )}
      </button>
    </>
  );
};

export default WizardReport;
