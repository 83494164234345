import { categoryModal } from "models/complaints/complaint-modals";
import { User } from "models/user-models";

export interface WizardOptionModal {
    id: string
    page_name: string
}

export interface UpdatedReportModal {
    system: categoryModal
    compliants: categoryModal[]
    subComplaints: categoryModal[]
    examinations: ExaminationModal[]
    impressions: categoryModal[]
    treatment_plans: categoryModal[]
}

export interface reportModal {

    user?: User;
    system: categoryModal
    duration?: number;
    durationType?: string;
    compliants?: categoryModal[];
    subComplaints: categoryModal[]
    subComplaintRemark?: string
    complaintRemark?: string
    impressionRemark?: string
    examinationRemark?: string
    examinations?: ExaminationModal[];
    impressions?: categoryModal[];
    treatment_plans?: categoryModal[];
    reportName?: string;
    createdOn?: Date;
    createdBy?: string;
}

export interface wizardDisplayModal {
    id: string;
    description?: string;
    system_id?: string[]
}
export interface defaultBtn {
    id: string;
    description?: string;
}


export interface WizardProps {
    selectedUser?: User;
    userReport?: UpdatedReportModal;
    systemList?: categoryModal[]
    complaintList?: categoryModal[]
    durationList?: wizardDisplayModal[]
    examinationList?: ExaminationModal[]
    impressionList?: EITModal[]
    treatmentList?: EITModal[]
    handleSelectedUser?: (User: User) => void;
    updateReportList?: (data: WizardOptionModal[], selectedSubExams: wizardDisplayModal[], selectedSubImp: wizardDisplayModal[], selectedSubComp: wizardDisplayModal[], selectedSubTreatPlan: wizardDisplayModal[]) => void;
    duration?: number
    setDuration?: (duration: number) => void
    durationType?: string
    setDurationType?: (durationType: string) => void
    setIsCompleted?: (isCompleted: boolean) => void
    complaintRemark?: string
    subComplaintRemark?: string
    impressionRemark?: string
    examinationRemark?: string
    setComplaintRemark?: (remarks: string) => void
    setSubComplaintRemark?: (remarks: string) => void
    setImpressionRemark?: (remarks: string) => void
    setExaminationRemark?: (remarks: string) => void
    data?: { description: string; value: { inputValue: string; unitValue: string } }[];
    setData?: React.Dispatch<React.SetStateAction<{ description: string; value: { inputValue: string; unitValue: string } }[]>>;

}

export interface wizardSelectionModal {
    pageID: number;
    pageName?: string;
    pageList: wizardPageListModal[];
}

export interface wizardPageListModal {
    id: string;
    description: string;
    isSelected: boolean;
}

export interface wizardIterationModal {
    iterationName: string;
    iterationState: boolean;
}

//Examination, Impression and Treatment modal
export interface EITModal {
    _id: string;
    parent_ids?: string[];
    parent_name?: string;
    description: string;
    scroll_downs?: string[];
    system_id: string[];
    added_by?: string;
    added_on?: string;
}

export interface DefaultBtnModelProps {
    desc: string;
    data: Array<{ description: string; value: { inputValue: string; unitValue: string } }>;
    isSelected: boolean;
    onSubmit: (desc: string, value: { inputValue: string; unitValue: string }) => void;
    isInData: boolean;
    onRemove: (desc: string) => void;
}


export interface ExaminationModal {
    _id: string;
    description: string;
    value?: { inputValue: string; unitValue: string };
    scroll_downs: string[]
    added_by?: string;
    added_on?: string;
}

export interface wizardHeadingModal {
    optionNumber?: number,
    heading?: string;
    subHeading?: string;
    isActive?: boolean;
}

export const wizardHeadingArray: wizardHeadingModal[] = [
    {
        optionNumber: 1,
        heading: "COMPLAINTS",
        subHeading: "What symptoms is the patient experiencing?",
        isActive: true
    },
    {
        optionNumber: 2,
        heading: "DURATION",
        subHeading: "When did the symptoms first appear?",
        isActive: false
    },
    {
        optionNumber: 3,
        heading: "EXAMINATION",
        subHeading: "What examinations or tests do you recommend for the patient?",
        isActive: false
    },
    {
        optionNumber: 4,
        heading: "IMPRESSION",
        subHeading: "What is your impression for the patient?",
        isActive: false
    },
    {
        optionNumber: 5,
        heading: "TREATMENT PLAN",
        subHeading: "What treatment plan do you recommend for the patient?",
        isActive: false
    },
]

export const wizardIterationArray: wizardIterationModal[] = [
    { iterationName: "Complaints", iterationState: true },
    { iterationName: "Duration", iterationState: false },
    { iterationName: "Examination", iterationState: false },
    { iterationName: "Impression", iterationState: false },
    { iterationName: "Treatment", iterationState: false }
]

export const durationListArray: wizardDisplayModal[] = [
    { id: "1day", description: "1 Day Ago", system_id: [] },
    { id: "3days", description: "3 Days Ago", system_id: [] },
    { id: "5days", description: "5 Days Ago", system_id: [] },
    { id: "1week", description: "1 Week Ago", system_id: [] },
    { id: "weekPlus", description: "More then 1 Week", system_id: [] },
]

export const formatReportData = (data: reportModal) => {
    let formattedData = '';

    // if (data.user) {
    //     formattedData += "User: \n"
    //     formattedData += "First Name: " + (data.user.fname ? data.user.fname : "")
    //     formattedData += "\nLast Name: " + (data.user.lname ? data.user.lname : "")
    //     formattedData += "\nEmail: " + (data.user.email ? data.user.email : "")
    //     formattedData += "\nDate of Birth: " + (data.user.dob ? new Date(data.user.dob).toDateString() : "")
    //     formattedData += "\nAge: " + (data.user.age ? data.user.age : "")
    // }

    if (data.compliants && data.compliants?.length > 0) {
        // formattedData += "\n\nComplaints: \n"
        formattedData += `C/O ${data.compliants[0].description} ${
            data.compliants[0].scroll_downs?.length > 0 
              ? `${data.compliants[0].scroll_downs.join(", ")}`
              : ""
          } for ${data.duration} ${data.durationType} \n`;
        formattedData += data.complaintRemark ? ` ${data.complaintRemark}` : "";
    }

    if (data.subComplaints && data.subComplaints?.length > 0) {
        data.subComplaints?.map((sub_complaint, index) => {
            const isLast = index === data.subComplaints?.length - 1;
            const separator = isLast ? '' : (index === data.subComplaints?.length - 2 ? ' and ' : ', ');
            if (index === 0) {
                formattedData += `\nPatient also has ${sub_complaint.description ? sub_complaint.description +
                    (sub_complaint.scroll_downs && sub_complaint.scroll_downs.length > 0 ? sub_complaint.scroll_downs.map((scroll_down, si) => {
                        return `${scroll_down}${sub_complaint.scroll_downs.length - 1 > si ? "," : ""}`;
                    }).join('') : '') : ''}${separator}`;
            } else {
                formattedData += `${sub_complaint.description ? sub_complaint.description +
                    (sub_complaint.scroll_downs && sub_complaint.scroll_downs.length > 0 ? sub_complaint.scroll_downs.map((scroll_down, si) => {
                        return `${scroll_down}${sub_complaint.scroll_downs.length - 1 > si ? "," : ""}`;
                    }).join('') : '') : ''}${separator}`;
            }
            
        })
    }






    if (data.examinations && data.examinations.length > 0) {
        formattedData += "\n\nOn Examination: \n";
    
        formattedData += data.examinations
            .map(item => {
                let examinationText = `${item.description}`; // Add the description
    
                // Add value details if present
                if (item.value?.inputValue || item.value?.unitValue) {
                    const inputValue = item.value?.inputValue ? item.value.inputValue : "undefined";
                    const unitValue = item.value?.unitValue ? (item as any)?.value?.unitValue === "Kilos"
                        ? "Kg"
                        : (item as any)?.value?.unitValue === "Pounds"
                        ? "Lb"
                        : (item as any)?.value?.unitValue === "Feets"
                        ? "ft"
                        : (item as any)?.value?.unitValue === "Meters"
                        ? "m"
                        : (item as any)?.value?.unitValue === "Celsius"
                        ? "C"
                        : (item as any)?.value?.unitValue === "Fahrenheit"
                        ? "F"
                        : (item as any)?.value?.unitValue : "";
                    examinationText += `: ${inputValue} ${unitValue}`.trim();
                }
    
                // Add scroll_downs if present
                if (item.scroll_downs && item.scroll_downs.length > 0) {
                    examinationText += `( ${item.scroll_downs.join(", ")} )`;
                }
    
                return examinationText;
            })
            .join('\n'); // Join all examination texts into a single string
    
        // Add examination remark if present
        formattedData += data.examinationRemark ? `\n${data.examinationRemark}` : "";
    }
    


    if (data.impressions && data.impressions.length > 0) {
        formattedData += "\n\nImpression: \n";
        formattedData += data.impressions
            .map(item => {
                let impressionText = `${item.description}`; // Add the description on a new line
                if (item.scroll_downs && item.scroll_downs.length > 0) {
                    // Join scroll_downs on the same line after the description
                    impressionText += ` ( ${item.scroll_downs.join(", ")} )`;
                }
    
                // No new line after scroll_downs, just add it on the same line
                return impressionText;
            })
            .join("\n"); // Add a newline between impressions
        
        formattedData += data.impressionRemark ? `\n${data.impressionRemark}` : "";
    }
    if (data.treatment_plans && data.treatment_plans.length > 0) {
        formattedData += "\n\nTreatment Plan: ";
        formattedData += data.treatment_plans
            .map(item => {
                let treatmentText = `${item.description}`; // Add the description on the same line
    
                if (item.scroll_downs && item.scroll_downs.length > 0) {
                    // Join scroll_downs on the same line
                    treatmentText += ` ( ${item.scroll_downs
                        .map((scroll_down, si) => `${scroll_down}${item.scroll_downs.length - 1 > si ? "," : ""}`)
                        .join(" ")} )`;
                }
    
                return treatmentText;
            })
            .join("\n"); // Add a newline between treatment plans
    }
    
    return formattedData;
};